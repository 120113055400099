<template>
  <div class="enterprise">
    <index-header></index-header>
    <div class="enterprise-body">
      <div class="en-main">
        <div class="em-left">
          <Menu :active-name="currentMenu" @on-select="onSelectMenu" width="220px" :open-names="openSub" ref="child">
            <MenuItem name="enterprise-management-center">
              <Icon type="ios-desktop" size="18" />
              企业看板
            </MenuItem>

            <MenuItem name="enterpriseConsultingManagement">
              <Icon type="md-text" size="18" />
              咨询管理
            </MenuItem>
            <Submenu name="order">
              <template slot="title">
                <Icon type="ios-list-box" size="18" />
                订单管理
              </template>
              <MenuItem name="patentOrder">专利订单管理</MenuItem>
              <MenuItem name="serviceOrder">服务订单管理</MenuItem>
            </Submenu>

            <Submenu name="patentService">
              <template slot="title">
                <Icon type="ios-hand" size="18" />
                专利托管
              </template>
              <MenuItem name="patentTrusteeshipCom">托管管理</MenuItem>
              <MenuItem name="annuaFreeCollection">年费代缴</MenuItem>
              <MenuItem name="salePatent">专利出售</MenuItem>
              <MenuItem name="findPatent">专利求购</MenuItem>
            </Submenu>

            <MenuItem name="enterprise-physical-examination">
              <Icon type="md-analytics" size="18" />
              企业体检
            </MenuItem>

            <!-- <Submenu name="science">
              <template slot="title">
                <Icon type="ios-flower" size="18" />
                科技政策/项目
              </template>
              <MenuItem name="science-policy">项目申报</MenuItem>
              <MenuItem name="enterpriseProjectLibrary">企业项目库</MenuItem>
            </Submenu> -->

            <Submenu name="demandManage">
              <template slot="title">
                <Icon type="md-list-box" size="18" />
                需求管理
              </template>
              <MenuItem name="enterpriseDemand">企业需求</MenuItem>
              <MenuItem name="enterpriseInfoMatching">企业信息匹配</MenuItem>
            </Submenu>


            <MenuItem name="enterpriseInfo">
              <Icon type="md-paper" size="18" />
              企业基础信息
            </MenuItem>

            <MenuItem name="accountManagement">
              <Icon type="ios-cog" size="18" />
              账号管理
            </MenuItem>

            <MenuItem name="sellerCenter">
              <Icon type="ios-briefcase" size="18" />
              卖家中心
            </MenuItem>

          </Menu>
        </div>
        <div class="em-blank"></div>
        <div class="em-right">
          <component :is="currentMenu"></component>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import { getEnterpriseRouteConfig } from "@/plugins/route";
import { mapGetters } from "vuex";
import { warnToast } from "@/plugins/tools/util";
import axios from "axios";
import baseUrl from "../../plugins/http/baseUrl";
import { footInfo2 } from "../../plugins/tools/footer";
import { hostname, projectHosts } from "../../config";

export default {
  name: "index",
  computed: {
    ...mapGetters({ "enterpriseState": "userStore/enterpriseState" })
  },
  watch: {
    $route: {
      handler: function(val, oldVal) {
        console.log(val);
        this.currentMenu = val.params.pgId;
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    this.currentMenu = this.$route.params.pgId;
    this.showSub();
    this.baseUrl = baseUrl;
    axios.get(`${baseUrl}/v1/site/one`).then(res => {
      if (res.status === 200) {
        let data = res.data.result;
        this.siteUrl = data.logoImageUrl;
        this.siteTitle = data.title;
        this.siteKeywords = data.keywords;
        this.siteDescription = data.description;
      }
    });
    // if(this.enterpriseState == 'UNCERTIFIED' ||this.enterpriseState == 'EXAMINING' ) {
    //   this.$nextTick(()=> {
    //     this.list_items.splice(1,4);
    //   })
    // }
  },
  components: {
    "indexHeader": () => import("../../components/index/indexHeader"),
    "science-policy": () => import("../../components/science/SciencePolicy/SciencePolicyCom.vue"),//项目申报
    "enterpriseProjectLibrary": () => import("../../components/science/SciencePolicy/EnterpriseProjectLibrary.vue"),//企业项目库
    "enterprise-school-connection": () => import("../../components/science/EnterpriseSchoolConnection.vue"),//需求管理
    "enterpriseDemand": () => import("../../components/science/EnterpriseSchoolConnection/EnterpriseDemand.vue"),//企业需求
    "enterpriseInfoMatching": () => import("../../components/science/EnterpriseSchoolConnection/EnterpriseInformationMatching.vue"),//企业信息匹配
    "enterprise-management-center": () => import("../../components/science/EnterpriseManagementCenter/EnterpriseKanban.vue"),//企业看板
    "enterprise-physical-examination": () => import("../../components/science/EnterprisePhysicalExamination/Index.vue"),//企业体检
    "patent-trusteeship": () => import("../../components/science/PatentTrusteeship.vue"),
    "enterpriseInfo": () => import("../../components/science/EnterpriseManagementCenter/EnterPriseInfo.vue"),//企业基础信息
    "enterpriseConsultingManagement": () => import("../../components/science/EnterpriseManagementCenter/EnterpriseConsultingManagement.vue"),//企业咨询管理
    "patentOrder": () => import("../../components/science/EnterpriseManagementCenter/PatentOrder.vue"),//专利订单管理
    "serviceOrder": () => import("../../components/science/EnterpriseManagementCenter/ServiceOrder.vue"),//服务订单管理
    "accountManagement": () => import("../../components/science/EnterpriseManagementCenter/AccountManagement.vue"),//账号管理
    "patentTrusteeshipCom": () => import("../../components/science/PatentTrusteeship/PatentTrusteeshipCom.vue"),//专利托管
    "annuaFreeCollection": () => import("../../components/science/PatentTrusteeship/AnnuaFreeCollection.vue"),//年费代缴
    "salePatent": () => import("../../components/science/PatentTrusteeship/SalePatent.vue"),//出售专利
    "findPatent": () => import("../../components/science/PatentTrusteeship/FindPatent.vue")//专利求购

  },
  data() {
    return {
      currentMenu: "",
      baseUrl: "",
      footInfo: "",
      siteUrl: "",
      siteKeywords: "",
      siteDescription: "",
      list_items: baseUrl.indexOf("minwf.cn") > -1 ? [
        { key: "enterprise-management-center", value: "企业管理中心" },
        { key: "enterprise-school-connection", value: "企校通" },
        { key: "patent-trusteeship", value: "专利托管" },
        { key: "enterprise-physical-examination", value: "企业体检" },
        { key: "science-policy", value: "科技政策" }
      ] : [
        { key: "enterprise-management-center", value: "企业管理中心" },
        { key: "enterprise-school-connection", value: "企校通" },
        { key: "patent-trusteeship", value: "专利托管" },
        { key: "enterprise-physical-examination", value: "企业体检" }
        // { key: "science-policy", value: "科技政策" }
      ]
    };
  },
  methods: {
    goIndex() {
      this.$router.push({
        path: "/"
      });
    },


    /*
     * @desc 动态展开菜单子项
     * @author  yangfeiyu 
     * @lasttime 2021-6-9 10:33:53
     * @param 
     */
    showSub() {
      this.openSub = [this.$route.params.openSub]
      this.$nextTick(()=> {
        this.$refs.child.updateOpened();
      });
    },
    // chooseCom(index) {
    //   let nav = getEnterpriseRouteConfig().filter(x => x.navIndex === index);
    //   if (this.enterpriseState === "UNCERTIFIED") {
    //     if (index !== 0) {
    //       this.$router.push({ path: "/enterprise/EnterpriseCertification" });
    //       return;
    //     }
    //   }
    //   if (this.enterpriseState === "EXAMINING") {
    //     if (index !== 0) {
    //       warnToast("企业认证审核中");
    //       return;
    //     }
    //   }
    //   if (index !== 2 && index !== 0) {
    //     this.$router.push({
    //       path: nav[0].path
    //     });
    //   } else {
    //     this.$router.push({
    //       path: nav[0].path,
    //       query: {
    //         id: 0
    //       }
    //     });
    //   }
    //
    // },

    onSelectMenu(name) {
      if (this.enterpriseState === "UNCERTIFIED") {
        if (name !== "enterprise-management-center" && name !== "accountManagement" && name !== "enterpriseConsultingManagement" && name !== "enterpriseInfo" && name !== "patentOrder" && name !== "serviceOrder") {
          this.$router.push({ path: "/enterprise/EnterpriseCertification" });
          return;
        }
      }
      if (this.enterpriseState === "EXAMINING") {
        if (name !== "enterprise-management-center" && name !== "accountManagement" && name !== "enterpriseConsultingManagement" && name !== "enterpriseInfo" && name !== "patentOrder" && name !== "serviceOrder") {
          warnToast("企业认证审核中");
          return;
        }
      }
      if (name === "sellerCenter") {
        this.currentMenu = "/enterprise/enterprise-management-center";
        window.open(`http://${projectHosts.seller}.${hostname}/broker-dist/`);
        return;
      }
      this.$router.push({
        path: name
      });
    }
  }

};
</script>

<style scoped lang="scss">

.enterprise {
  display: flex;
  flex-direction: column;

}

.enterprise-body {
  background-color: #F7F8F9;
  display: flex;
  justify-content: center;
}

.en-main {
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  margin-top: 20px;
}

.em-left {
  width: 220px;
  flex-shrink: 0;

}

.em-blank {
  background-color: #F7F8F9;
  width: 20px;
}

.em-right {
  width: 980px;
  background-color: #FFFFFF;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - 60px);
}

.ivu-menu-vertical.ivu-menu-light:after {
  width: 0;
}

.ivu-menu {
  padding: 20px 0;
  border-radius: 8px;
}

</style>
